<template>
  <div>
      <Row>
        <i-col span="24" class="text-right">
          <i-button type="primary" class="m-r-5" size="small"  @click="showHistory=true">审批记录</i-button>
          <!-- <i-button type="primary" class="m-r-5" size="small" @click="handleShowOrder">查看订单详情</i-button> -->
          <i-button type="primary" class="m-r-5" size="small"  @click="handleDownload">下载结算单</i-button>
          <i-button type="error" class="m-r-5" size="small"  @click="handleInvalid" v-if="nullifiable">作废</i-button>
          <i-button type="error" class="m-r-5" size="small" @click="handleChangeCode">更改编号</i-button>
        </i-col>
      </Row>

      <statement-detail-component :id="id" @on-inited="handleInited" />

      <invalid-component :desc="desc" :id="id" :show="showInvalid" @on-change="handleReasonChanged" @on-clicked="handleConfirmed" @on-view-changed="handleViewChanged"></invalid-component>
      <Modal v-model="showHistory" :width="800"  footer-hide>
        <history :relateId="id" :type="7"></history>
      </Modal>
  </div>
</template>

<script>
import { downloadFileRequest } from '@/utils/download'

import StatementDetailComponent from '@/components/statement/StatementDetailComponent'
import invalidComponent from '@/components/contract/contractDetail/detail/InvalidComponent'

import History from '@/components/approval/History'
import { invalidStatement, changeStatementCode } from '@/api/scp/statement'

export default {
  props: {
    id: {
      required: true
    }
  },
  components: {
    History, StatementDetailComponent, invalidComponent
  },
  data () {
    return {
      showInvalid: false,
      desc: '',
      showHistory: false,
      nullifiable: false,
      orderId: 0,
      statement: {}
    }
  },
  methods: {
    handleInited (statement) {
      this.statement = statement
      // let startDate = new Date(statement.startDate)
      const nullifiable = statement.status !== -1
      this.nullifiable = nullifiable
      this.orderId = statement.orderIdList[0]
    },
    handleReasonChanged (reason) {
      this.desc = reason
    },
    handleViewChanged (show) {
      this.showInvalid = show
    },
    handleInvalid () {
      this.showInvalid = true
    },
    handleConfirmed () {
      invalidStatement({ statementId: this.id, invalidMark: this.desc }).then(
        res => {
          if (res && !res.errcode) {
            this.$Notice.success({ desc: '结算单作废成功', title: '操作成功' })
            this.showInvalid = false
            this.$router.push({ name: 'Statement-manage-page' })
          }
        }
      )
    },
    handleChangeCode () {
      let newCode = this.statement.code
      this.$Modal.confirm({
        title: '更改结算单的编号',
        render: h => {
          return h('Input', {
            props: {
              value: newCode
            },
            on: {
              'on-change': event => {
                newCode = event.target.value
              }
            }

          })
        },
        onOk: () => {
          changeStatementCode({ statementId: this.id, statementCode: newCode }).then(res => {
            if (res && !res.errcode) {
              this.$Notice.success({ desc: '更改编号成功' })
              window.location.reload()
            }
          })
        }
      })
    },
    handleDownload () {
      let downloadFilename = '广告发布结算单('
      downloadFilename += this.statement.advertiserName
      downloadFilename += this.statement.code
      downloadFilename += ').doc'

      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-scp/v1/statement/downloadStatement', { statementId: this.id }, downloadFilename)
    }
  }
}
</script>
